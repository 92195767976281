<template>
  <div class="content">
    <img src="@/image/logog.png" alt="" class="logo">
    <div class="text1">{{$t('noopen')}}</div>
    <div class="text2">ELEPHANT GOLD ALLIANCE</div>
    <img src="@/image/noopen.png" alt="" class="noopen">
  </div>
</template>
<script>
export default {
  name: 'web3',
	data() {
		return {

    }
  },
}
</script>

<style lang="less" scoped>
  .content{
    min-height: 100%;
    background: #f8fafe;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .logo{
      height: 36px;
    }
    .noopen{
      width: 100%;
    }
    .text1{
      font-size: 16px;
      color: #aeb8c6;
      padding: 10px 0;
    }
    .text2{
      font-size: 12px;
      color: #cccee7;
    }
  }
</style>